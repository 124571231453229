<h1 mat-dialog-title class="confirmation-dialog__title" mat-dialog-title>{{ data.title }} </h1>
<div mat-dialog-content>
  <p class="confirmation-dialog__text">{{ data.description }}</p>
</div>
<div mat-dialog-actions class="confirmation-dialog__actions">
    <button mat-button color="warn"
    class="confirmation-dialog__cancel-button btn btn-secondary btn-secondary-inverse btn-secondary-inverse-bordered"
    cdkFocusInitial
    (click)="onNoClick()">Cancelar</button>
    <button mat-button color="primary"
    class="confirmation-dialog__accept-button btn btn-primary btn-primary-inverse btn-primary-inverse-bordered" 
    (click)="onYesClick()">Aceptar</button>
</div>
