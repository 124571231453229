import { Type } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Geometry } from 'ol/geom';
import { QuestionnaireItemPreferences } from 'src/app/questionnaire-generator/dialogs/questionnaire-item-preferences';
import { Dialog } from './dialog';
import { MetricUnit } from './metric-unit';
import { QuestionnaireCondition } from './questionnaire-conditon';

export type QuestionnaireFetchableData = 'land' | 'crop';
export type QuestionnaireItemType = 'root' | 'container' | 'subtitle' | 'information' | 'hyperlink' | 'input' | 'select' |
  'option' | 'photoUpload' | 'landInfo' | 'sampleMap' | 'multiColumnCheck' | 'repetition';
export type QuestionnaireQuestionType = 'simple' | 'multiple';
export type QuestionnaireRestrictionType = 'text' | 'number' | 'date' | 'email';

export interface Deactivatable {
  active: boolean;
}

export interface Nesteable<T> {
  children: T[];
}

export interface Repeteable {
  template: QuestionnaireContainer;
}

export interface QuestionnaireMultiColumnCheck extends QuestionnaireContent, Nesteable<QuestionnaireQuestion>, Deactivatable {
  description: string;
}

export interface Tag {
  _id: string;
  name: string;
  description: string;
}

export interface QuestionOption extends QuestionnaireContent {
  displayValue: string;
  value: number | string | Date | null;

}

export interface QuestionnaireContent {
  _id: string;
  name?: string;
  parentId: string;
  type: QuestionnaireItemType; // interpreted by the frontend when a element cannot be used
  restrictions?: QuestionnaireRestrictions;
}

export interface QuestionnaireRestrictions {
  type?: QuestionnaireRestrictionType;
  required?: boolean;
  readonly?: boolean;
  min?: number | string;
  max?: number | string;
  maxSize?: number;
  hidden?: boolean;
  prefix?: MetricUnit;
  suffix?: MetricUnit;
}

export interface QuestionnaireContainer extends QuestionnaireContent, Nesteable<QuestionnaireInformation
  | QuestionnaireSubtitle
  | QuestionnaireHyperlink
  | QuestionnaireContainer
  | QuestionnaireQuestion> {
}

export interface QuestionnaireInformation extends QuestionnaireContent {
  description: string;
}

export interface QuestionnaireLandInfo extends QuestionnaireContent {
  dataToLoad: QuestionnaireFetchableData;
}

export interface QuestionnaireMap extends QuestionnaireContent, Repeteable {
  samples: QuestionnaireMapSample[];
  geometry: Geometry;
}

export interface QuestionnaireMapSample {
  _id: string;
  sampleTitle: string;
  geometry: Geometry;
  sampleDescription: string;
}

export interface QuestionnaireSubtitle extends QuestionnaireContent {
  description: string;
}

export interface QuestionnaireHyperlink extends QuestionnaireContent {
  target: string;
  displayText: string;
}

export interface QuestionnaireImageMIMETypes {
  jpg: boolean;
  png: boolean;
  gif: boolean;
}

export interface QuestionnairePhotoUpload extends QuestionnaireContent {
  description: string;
  allowedMIMETypes: QuestionnaireImageMIMETypes;
}

export interface QuestionnaireQuestion extends QuestionnaireContent, Deactivatable {
  questionType: QuestionnaireQuestionType;
  description: string;
  tags: Tag[];
  landTag: string;
  options: Array<QuestionOption>;
  formula?: string;
  conditions: Array<QuestionnaireCondition>;
  answer?: any;
}

export interface QuestionnaireWrapper extends Deactivatable {
  _id: string;
  structureQuestionnaire: Questionnaire;
  coreQuestionnaire: boolean;
  onSite: boolean;
}

export interface Questionnaire extends Nesteable<QuestionnaireContainer> {
  _id: string;
  name: string;
  type: 'root';
  active?: boolean;
  onSite: boolean;
  coreQuestionnaire: boolean;
}

export interface QuestionnaireRepetition extends QuestionnaireContent, Repeteable {
  repetitions: QuestionnaireContainer[];
  dynamicSize: boolean;
}

export interface PreferencesSelectedEvent {
  source: FormGroup;
  dialog: Type<Dialog<QuestionnaireItemPreferences>>;
}


export function isQuestionnaireSubtitle(
  element: QuestionnaireContent
): element is QuestionnaireSubtitle {
  return (element as QuestionnaireInformation).description !== undefined && element.type === 'subtitle';
}

export function isQuestionnaireInformation(
  element: QuestionnaireContent
): element is QuestionnaireInformation {
  return (element as QuestionnaireInformation).description !== undefined && element.type === 'information';
}

export function isNesteable(element: any): element is Nesteable<any> {
  return element && element.children !== undefined;
}

export function isRepeteable(element: any): element is Repeteable {
  return element && element.template !== undefined;
}

export function isQuestionnaireContainer(
  element: QuestionnaireContent
): element is QuestionnaireContainer {
  return element && element.type === 'container' && isNesteable(element);
}

export function isQuestionnaireQuestion(
  element: Partial<QuestionnaireContent | any>
): element is QuestionnaireQuestion {
  return element && (element as QuestionnaireQuestion).questionType !== undefined;
}

export function isQuestionnaireInput(
  element: QuestionnaireContent
): element is QuestionnaireQuestion {
  return (
    element &&
    (element as QuestionnaireQuestion).questionType !== undefined &&
    (element as QuestionnaireQuestion).type === 'input'
  );
}

export function isQuestionnaireRoot(element: any): element is Questionnaire {
  return element && element.type === 'root';
}

export function isQuestionnaireSelect(
  element: QuestionnaireContent
): element is QuestionnaireQuestion {
  return (
    element &&
    (element as QuestionnaireQuestion).questionType !== undefined &&
    (element as QuestionnaireQuestion).type === 'select'
  );
}

export function isQuestionnaireRepetition(element: QuestionnaireContent): element is QuestionnaireRepetition {
  return element && (element as QuestionnaireRepetition).template !== undefined && element.type === 'repetition';
}

export function isQuestionnaireMap(element: QuestionnaireContent): element is QuestionnaireMap {
  return element && (element as QuestionnaireMap).template !== undefined && element.type === 'sampleMap';
}

export function isQuestionOption(element: QuestionnaireContent): element is QuestionOption {
  return element && (element as QuestionOption).value !== undefined && (element as QuestionOption)._id !== undefined && element.type === 'option';
}

export interface FlattenedContent<T> {
  level: number;
  item: T;
  expandable: boolean;
}
