import { DateTime } from 'luxon';

export function formatLuxonDate(date: string | DateTime): string {
    let formattedDate: string;
    if (date instanceof DateTime) {
      formattedDate = date.toISO();
    } else {
      formattedDate = date;
    }

    return formattedDate;
}

export function metersToHectares(measure: number): number {
  return measure / 10000;
}

export function hectaresToMeters(measure: number): number {
  return measure * 10000;
}