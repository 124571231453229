import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, switchMap, takeUntil, tap, timeout } from 'rxjs/operators';
import { BehaviorSubject, interval, Observable, Subject } from 'rxjs';
import { jwtDecode } from 'jwt-decode';

import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { RoleType } from './model/role';
import { UsersService } from '../users/users.service';
import { User } from './model/user';

export interface SessionState {
  loggedIn: boolean;
  message: string;
}

const notSignedInMessage = `Not signed in`;

@Injectable({ providedIn: 'root' })
export class SessionService {
  private _isLoggedIn = false;
  private sessionStateSubject = new BehaviorSubject<SessionState>({
    loggedIn: false,
    message: notSignedInMessage
  });
  private remainingSessionTimeSubject = new BehaviorSubject<number>(0);

  accessToken: string;
  expiresAt: number;

  private unsubscribeInterval: Subject<any>;

  public get isLoggedIn(): boolean {
    return this._isLoggedIn;
  }

  sessionState$ = this.sessionStateSubject.asObservable();
  remainingSessionTime$ = this.remainingSessionTimeSubject.asObservable();

  constructor(private http: HttpClient, private router: Router, private userService: UsersService) {
    const token = this.getToken();
    const expiresIn = this.getExpiresAt();
    if (token) {
      this.accessToken = token;
      this._isLoggedIn = true;
    }
    if (expiresIn) {
      this.expiresAt = expiresIn;
      this._registerSessionCheckInterval();
    }
  }

  private _registerSessionCheckInterval(): void {
    this.unsubscribeInterval = new Subject();
    const seconds = interval(1000);
    seconds.pipe(
      takeUntil(this.unsubscribeInterval),
      timeout(new Date(this.expiresAt)),
    ).subscribe({
      next: () => {
        const remainingTime = Math.max(this.expiresAt - Date.now(), 0);
        this.remainingSessionTimeSubject.next(remainingTime);
      },
      error: (err) => {
        this.logout(); // logout when the timeout fires
      }
    });
  }

  private _completeSessionCheckInterval(): void {
    this.unsubscribeInterval.next(null);
    this.unsubscribeInterval.complete();
    this.remainingSessionTimeSubject.next(0);
  }

  signin(email: string, password: string): Observable<User> {
    const root = environment.API;
    const signinUrl = `${root}/auth/signin`;
    const body = {
      email,
      password
    };
    const headers = new HttpHeaders({'x-access-device': 'auth-web'});
    const options = {headers};
    return this.http.post<{ token: string, expires: number }>(signinUrl, body, options).pipe(
      map(res => {
        if (res?.token) {
          const message = `Welcome ${email}`;
          this.storeSessionToken(res.token);
          this.storeTokenExpiration(res.expires);
          if (this.unsubscribeInterval) {
            this._completeSessionCheckInterval();
          }
          this._registerSessionCheckInterval();
          this.sessionStateSubject.next({ loggedIn: true, message });
          this._isLoggedIn = true;
        } else {
          this.logout();
        }
      }),
      switchMap(() => {
        const userId = this.loggedUserId;
        return this.userService.find(userId).pipe(
          tap(user => {
            this.storeCountry(user);
          })
        );
      }),
    );
  }

  get loggedUserId(): string {
    const decoded = this._decode();
    return decoded.id;
  }

  get country(): string {
    return sessionStorage.getItem('country');
  }

  private _decode(): { id: string, role: RoleType, exp: number, iat: number } {
    const decoded: { id: string, role: RoleType, exp: number, iat: number } = jwtDecode(this.accessToken);
    return decoded;
  }

  private storeCountry(user: User): void {
    sessionStorage.setItem('country', user.personalData.address.country);
  }

  private storeSessionToken(token: string): void {
    sessionStorage.setItem('session', token);
    this.accessToken = this.getToken();
  }

  private storeTokenExpiration(expiresIn: number): void {
    const today = Date.now();
    const expirationDate = new Date(today + (expiresIn * 1000)).getTime();
    sessionStorage.setItem('session_expires', String(expirationDate));
    this.expiresAt = this.getExpiresAt();
  }

  private getToken(): string {
    return sessionStorage.getItem('session');
  }

  private getExpiresAt(): number {
    return +sessionStorage.getItem('session_expires');
  }

  private revokeToken(): void {
    sessionStorage.removeItem('session');
    sessionStorage.removeItem('session_expires');
    this.accessToken = null;
  }

  refreshToken() {
    // TODO: implement a refresh

  }

  getRole(): RoleType {
    const { role } = this._decode();
    return role;
  }

  logout(): void {
    this.revokeToken();
    this._completeSessionCheckInterval();
    this.sessionStateSubject.next({ loggedIn: false, message: notSignedInMessage });
    this._isLoggedIn = false;
    this.router.navigate(['logout']);
  }
}
