import { Injectable } from '@angular/core';
import { SidebarRouteItem, sidebarRoutes } from '../../sidebar/sidebar-routes';

@Injectable({
  providedIn: 'root'
})
export class NavigationHierarchyLocalizeService {

  constructor() { }

  localize(text: string, extras?: { targetLanguage: string }): string {
    let localizedText: string;

    const route = this._searchInSidebarRoutes(text);
    localizedText = route ? route.title : null;
    if (localizedText === null) {
      localizedText = this._localizeNonRouteTextOrClearIt(text);
    }
    localizedText = this._isValidRouteSegment(localizedText) ? localizedText : '';

    return localizedText;
  }

  private _searchInSidebarRoutes(routeToSearch: string): SidebarRouteItem {
    let foundSidebarRoute = sidebarRoutes.find(route => route.path === routeToSearch);

    if (! foundSidebarRoute) {
      sidebarRoutes.some(route => {
        const foundRoute = route.submenu ? route.submenu.find(submenu => submenu.path === routeToSearch) : null;
        if (foundRoute) {
          foundSidebarRoute = foundRoute;
        }
        return !!foundRoute;
      });
    }

    return foundSidebarRoute;
  }

  private _localizeNonRouteTextOrClearIt(text: string): string {
    let localizedText: string;

    switch (text) {
      case 'create':
        localizedText = 'crear';
        break;
      case 'edit':
        localizedText = 'modificar';
        break;
      case 'validate':
        localizedText = 'validar';
        break;
      case 'location':
        localizedText = 'localización';
        break;
      case 'client':
        localizedText = 'cliente';
        break;
      case 'land':
        localizedText = 'predio';
        break;
      default:
        localizedText = '';
    }

    return localizedText;
  }

  private _isValidRouteSegment(text: string): boolean {
    let valid = true;

    if (text === 'usuario' || text === '' || text === null) {
      valid = false;
    }

    return valid;
  }
}
