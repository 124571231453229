import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';
import { AuthorizationPageComponent } from './pages/authorization-page/authorization-page.component';
import { LogoutComponent } from './logout/logout.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { Role } from './core/model/role';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { DisplayRolePipe } from './shared/pipes/displayRole.pipe';
import { WorkScheduleFormService } from './work-schedules/services/work-schedule-form.service';
import { QuestionnaireHandlerService } from './questionnaire-generator/services/questionnaire-handler.service';

const routes: Routes = [
  {
    path: '',
    component: DashboardPageComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: {
      allowedRoles: [
        { role: 'developerFull', canWrite: true },
        { role: 'administratorGeneral', canWrite: true },
        { role: 'coordinatorStatal', canWrite: true },
        { role: 'coordinatorRegional', canWrite: true },
        { role: 'technical', canWrite: true },
        { role: 'farmer', canWrite: true },
        { role: 'company', canWrite: true },
        { role: 'group', canWrite: true },
        { role: 'institution', canWrite: true }
      ] as Role[]
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./welcome/welcome-routing').then(m => m.welcomeRoutes),
        data: {
          allowedRoles: [
            { role: 'developerFull', canWrite: true },
            { role: 'administratorGeneral', canWrite: true },
            { role: 'coordinatorStatal', canWrite: true },
            { role: 'coordinatorRegional', canWrite: true },
            { role: 'technical', canWrite: true },
            { role: 'farmer', canWrite: true },
            { role: 'company', canWrite: true },
            { role: 'group', canWrite: true },
            { role: 'institution', canWrite: true }
          ] as Role[]
        },
      },
      {
        path: 'profile',
        loadChildren: () => import('./profile/profile-routing').then(m => m.profileRoutes),
        data: {
          allowedRoles: [
            { role: 'developerFull', canWrite: true },
            { role: 'administratorGeneral', canWrite: true },
            { role: 'coordinatorStatal', canWrite: true },
            { role: 'coordinatorRegional', canWrite: true },
            { role: 'technical', canWrite: true },
            { role: 'farmer', canWrite: true },
            { role: 'company', canWrite: true },
            { role: 'group', canWrite: true },
            { role: 'institution', canWrite: true }
          ] as Role[]
        },
      },
      {
        path: 'logout',
        component: LogoutComponent,
        data: {
          allowedRoles: [
            { role: 'developerFull', canWrite: true },
            { role: 'administratorGeneral', canWrite: true },
            { role: 'coordinatorStatal', canWrite: true },
            { role: 'coordinatorRegional', canWrite: true },
            { role: 'technical', canWrite: true },
            { role: 'farmer', canWrite: true },
            { role: 'company', canWrite: true },
            { role: 'group', canWrite: true },
            { role: 'institution', canWrite: true }
          ] as Role[]
        },
      },
      {
        path: 'users',
        loadChildren: () => import('./users/users-routing').then(m => m.usersRoutes),
        providers: [
          DisplayRolePipe
        ],
        data: {
          allowedRoles: [
            { role: 'developerFull', canWrite: true },
            { role: 'administratorGeneral', canWrite: true },
            { role: 'coordinatorStatal', canWrite: true },
            { role: 'coordinatorRegional', canWrite: true },
            { role: 'company', canWrite: true },
            { role: 'group', canWrite: true },
            { role: 'institution', canWrite: true }
          ] as Role[]
        },
      },
      {
        path: 'crops',
        loadChildren: () => import('./crops/crops-routing').then(m => m.cropsRoutes),
        data: {
          allowedRoles: [
            { role: 'developerFull', canWrite: true },
            { role: 'administratorGeneral', canWrite: true },
            { role: 'coordinatorStatal', canWrite: true },
          ] as Role[]
        },
      },
      {
        path: 'work-schedules',
        loadChildren: () => import('./work-schedules/work-schedules-routing').then(m => m.workScheduleRoutes),
        providers: [
          WorkScheduleFormService,
          QuestionnaireHandlerService,
        ],
        data: {
          allowedRoles: [
            { role: 'developerFull', canWrite: true },
            { role: 'administratorGeneral', canWrite: true },
            { role: 'coordinatorStatal', canWrite: true },
            { role: 'coordinatorRegional', canWrite: false },
            { role: 'technical', canWrite: false },
            { role: 'farmer', canWrite: false },
            { role: 'company', canWrite: false },
            { role: 'group', canWrite: false },
            { role: 'institution', canWrite: false }
          ] as Role[]
        },
      },
      {
        path: 'lands',
        loadChildren: () => import('./lands/lands-routing').then(m => m.landRoutes),
        providers: [
        ],
        data: {
          allowedRoles: [
            { role: 'developerFull', canWrite: true },
            { role: 'administratorGeneral', canWrite: true },
            { role: 'coordinatorStatal', canWrite: true },
            { role: 'coordinatorRegional', canWrite: true },
            { role: 'technical', canWrite: false },
            { role: 'farmer', canWrite: false },
            { role: 'company', canWrite: false },
            { role: 'group', canWrite: false },
            { role: 'institution', canWrite: false }
          ] as Role[]
        },
      },
      {
        path: 'assignations',
        loadChildren: () => import('./assignations/assignations-routing').then(m => m.assignationRoutes),
        data: {
          allowedRoles: [
            { role: 'developerFull', canWrite: true },
            { role: 'administratorGeneral', canWrite: true },
            { role: 'coordinatorStatal', canWrite: true },
            { role: 'coordinatorRegional', canWrite: true },
          ] as Role[]
        },
      },
      {
        path: 'questionnaires',
        loadChildren: () => import('./questionnaires/questionnaires-routing').then(m => m.questionnaireRoutes),
        data: {
          allowedRoles: [
            { role: 'developerFull', canWrite: true },
            { role: 'administratorGeneral', canWrite: true },
            { role: 'coordinatorStatal', canWrite: false },
            { role: 'coordinatorRegional', canWrite: false },
          ] as Role[]
        },
      },
      {
        path: 'results',
        providers: [
        ],
        loadChildren: () => import('./results/results-routing').then(m => m.resultsRoutes),
        data: {
          allowedRoles: [
            { role: 'developerFull', canWrite: true },
            { role: 'administratorGeneral', canWrite: true },
            { role: 'coordinatorStatal', canWrite: true },
            { role: 'coordinatorRegional', canWrite: true },
            { role: 'technical', canWrite: true },
            { role: 'farmer', canWrite: true },
            { role: 'company', canWrite: true },
            { role: 'group', canWrite: true },
            { role: 'institution', canWrite: true }
          ] as Role[]
        },
      }
    ]
  },
  {
    path: '',
    component: AuthorizationPageComponent,
    children: [
      {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent
      },
      {
        path: '',
        loadChildren: () => import('./session/session-routing').then(m => m.sessionRoutes)
      },
    ]
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
