import { Injectable } from '@angular/core';
import { Role } from '../model/role';
import { SessionService } from '../session.service';

@Injectable({
  providedIn: 'root'
})
export class RoleAccessService {

  constructor(private sessionService: SessionService) { }

  hasAccess(allowedRoles: Role[]): boolean {
    const loggedUserRole = this.sessionService.getRole();

    return !! allowedRoles.find(allowedRole => loggedUserRole === allowedRole.role);
  }

  canWrite(allowedRoles: Role[]): boolean {
    const loggedUserRole = this.sessionService.getRole();

    return !! allowedRoles.find(allowedRole => loggedUserRole === allowedRole.role && allowedRole.canWrite);
  }
}
