
<section class="privacy-policy">
    <h2 class="title">Aviso de privacidad de Plataforma de Control Atider</h2>
    <p>La privacidad de la información de los clientes es muy importante para Plataforma de Control Atider, cuando
        utiliza nuestros servicios queremos que le quede claro en todo momento como utilizamos su información
        y para que la almacenamos. Esta declaración de privacidad se aplica a los servicios de Plataforma de Control
        Atider.</p>
    <h3 class="title">Información que recoge Plataforma de Control Atider</h3>
    
    <h4 class="title">Información que creas o nos proporcionas</h4>
    <ul>
        <li>Información de la cuenta: Es la información que se solicita al momento que se crea una cuenta,
            por ejemplo, nombre y fecha de nacimiento. Plataforma de Control Atider indicará de forma implícita
            la información que deben llenarse de forma obligatoria. En caso de no introducir esos datos no
            será posible configurar una cuenta.
        </li>
        <li>
            Datos que creas con Plataforma de Control Atider: Datos que creas con Plataforma de Control Atider,
            por ejemplo: la información de tus predios, cantidad cosechada de cultivo en un ciclo y la
            información llenada al contestar un cuestionario.
        </li>
        <li>
            Información detallada de ubicación: La información de ubicación se recoge en forma de señales
            de GPS enviadas por el dispositivo móvil desde el que se ha instalado la aplicación. Esta
            información solo se recoge al momento usar las funciones de registrar predios y al contestar
            cuestionarios que tengan como requerimiento ser contestados en campo. La finalidad de recoger
            esta información es asegurar que el usuario se encuentra en campo al momento de realizar estas
            funciones.
        </li>
        <li>
            Metadatos: Plataforma de Control Atider puede recoger que tipo de dispositivo tienes al momento de
            iniciar sesión (móvil o pc).
        </li>
    </ul>
    
    <h3 class="title">Para que se recogen estos datos</h3>
    <h4 class="title">Prestar nuestros servicios</h4>
    <ul>
        <li>
            Utilizamos tus datos de ubicación geográfica para ofrecerte servicios creación de predios y
            responder cuestionarios vinculados a un predio.
            Medir el rendimiento
        </li>
        <li>
            Utilizamos datos en herramientas de análisis y mediciones para saber como utilizan nuestros
            servicios. Por ejemplo, analizamos los resultados de los cuestionarios para obtener el rendimiento
            que se tiene en un predio.
        </li>
    </ul>
    
    <h4 class="title">Comunicarnos contigo</h4>
    <ul>
        <li>
            Podemos usar la dirección de correo electrónico o número de teléfono que nos has facilitado para
            enviarte avisos, notificaciones e información adicional relacionada con los servicios.
        </li>
    </ul>
    
    <h3 class="title">Información de Socios</h3>
    <p>
        Plataforma de Control Atider no comparte información sobre usted o de las actividades que realiza dentro de
        nuestro sitio con ninguno de nuestros socios.
    </p>
    <h3 class="title">Modificaciones al Aviso de Privacidad</h3>
    <p>
        Plataforma de Control Atider se reserva el derecho a efectuar en cualquier momento modificaciones al
        presente Aviso de Privacidad, estas modificaciones estarán disponibles en la dirección <a href="https://www.atider.com">www.atider.com</a>
    </p>
</section>
