<mat-sidenav-container class="sidebar">
  <mat-sidenav #sidenav mode="side" class="sidebar__sidenav" position="end">
    <div class="sidebar__header">
      <img
        class="sidebar__logo"
        alt="Atider Logo"
        src="../../assets/images/atider_logo_green.png"
        (click)="navigateHome()">
      <div class="sidebar__user-info">
        <img class="sidebar__user-info__photo" [src]="profilePhoto || defaultProfilePhoto">
        <span class="sidebar__user-info__greeting">Hola</span>
        <b>{{ user?.mainData.name | titlecase }} {{ user?.mainData.lastName | titlecase }}</b>
      </div>
    </div>
    <mat-selection-list class="sidebar__menu-list"
      [multiple]="false"
      [hideSingleSelectionIndicator]="true"
      (selectionChange)="handleNavigationChange($event)">
      <!-- <mat-accordion> -->
      @for (route of routes; track route) {
        <ng-container *appAccessControl="route.allowedRoles">
          @if (! route.submenu || route.submenu.length === 0) {
            <mat-list-option class="sidebar__sidenav-option"
              [value]="route" [selected]="selectionModel.isSelected(route)">
              <mat-icon matListItemIcon [svgIcon]="(iconService.addSvg(route.icon) | async).iconName"></mat-icon>
              <div matListItemTitle>{{ route.title }}</div>
            </mat-list-option>
          } @else {
            <mat-expansion-panel [expanded]="isChildSelected(route)">
              <mat-expansion-panel-header>
                <mat-panel-title class="sidebar__sidenav-option">
                  <mat-icon matListItemIcon [svgIcon]="(iconService.addSvg(route.icon) | async).iconName"></mat-icon>
                </mat-panel-title>
                <mat-panel-description class="sidebar__sidenav-option">
                  <div>{{ route.title }}</div>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <ng-container *appAccessControl="route.allowedRoles">
                @for (submenu of route.submenu; track submenu) {
                  <mat-list-option class="sidebar__sidenav-option"
                    [value]="submenu" [selected]="selectionModel.isSelected(submenu)">
                    <div matListItemTitle>{{ submenu.title }}</div>
                  </mat-list-option>
                }
              </ng-container>
            </mat-expansion-panel>
          }
        </ng-container>
      }
    <!-- </mat-accordion> -->
  </mat-selection-list>
</mat-sidenav>

<!-- Collapsed Sidebar -->
<mat-sidenav-content class="sidebar__content">
  <div class="sidebar__container">
    <ng-content></ng-content>
  </div>
  <button class="sidebar__toggle-button" mat-mini-fab color="link" (click)="toggleSidebarCollapse(sidenav)"
    [ngClass]="{ 'collapsed': ! sidenav.opened }">
    <mat-icon [svgIcon]="(iconService.addSvg(sidenav.opened ? faChevronRight : faChevronLeft) | async).iconName"></mat-icon>
  </button>
  <div class="sidebar__sidenav-mini" [class.opened]="sidenav.opened">
    <div class="sidebar__header">
      <img
        class="sidebar__logo"
        alt="Atider Logo"
        src="../../assets/images/atider_logo_green.png"
        (click)="navigateHome()">
    </div>

    <mat-selection-list class="sidebar__menu-list"
      [multiple]="false"
      [hideSingleSelectionIndicator]="true"
      (selectionChange)="handleNavigationChange($event)">
      <!-- <mat-accordion> -->
      @for (route of routes; track route) {
        <ng-container *appAccessControl="route.allowedRoles">
          @if (! route.submenu || route.submenu.length === 0) {
            <mat-list-option class="sidebar__sidenav-option"
              [value]="route" [selected]="selectionModel.isSelected(route)">
              <mat-icon matListItemTitle [svgIcon]="(iconService.addSvg(route.icon) | async).iconName"></mat-icon>
            </mat-list-option>
          } @else {
            <mat-expansion-panel [expanded]="isChildSelected(route)" hideToggle>
              <mat-expansion-panel-header>
                <mat-panel-title class="sidebar__sidenav-option">
                  <mat-icon matListItemTitle [svgIcon]="(iconService.addSvg(route.icon) | async).iconName"></mat-icon>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-container *appAccessControl="route.allowedRoles">
                @for (submenu of route.submenu; track submenu) {
                  <mat-list-option class="sidebar__sidenav-option"
                    [value]="submenu" [selected]="selectionModel.isSelected(submenu)">
                    <div matListItemTitle>{{ submenu.title[0] }}</div>
                  </mat-list-option>
                }
              </ng-container>
            </mat-expansion-panel>
          }
        </ng-container>
      }
    <!-- </mat-accordion> -->
  </mat-selection-list>
</div>
</mat-sidenav-content>
</mat-sidenav-container>
