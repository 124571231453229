import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faBook, faClipboard, faClipboardCheck, faCloud, faLayerGroup, faMountain, faSeedling, faUser, faUserCog, faUsers } from '@fortawesome/free-solid-svg-icons';
import { Role } from '../core/model/role';

export interface SidebarRouteItem {
    icon?: IconDefinition;
    title: string;
    path: string;
    submenu?: SidebarRouteItem[];
    parent?: SidebarRouteItem;
    allowedRoles: Role[];
}

const _sidebarRoutes: SidebarRouteItem[] = [
    {
        icon: faUser,
        title: 'Mi cuenta',
        path: '',
        parent: null,
        submenu: [
            {
                title: 'Perfil',
                path: 'profile',
                allowedRoles: [
                    { role: 'developerFull', canWrite: true },
                    { role: 'administratorGeneral', canWrite: true },
                    { role: 'coordinatorStatal', canWrite: true },
                    { role: 'coordinatorRegional', canWrite: true },
                    { role: 'technical', canWrite: true },
                    { role: 'farmer', canWrite: true },
                    { role: 'company', canWrite: true },
                    { role: 'group', canWrite: true },
                    { role: 'institution', canWrite: true }
                ] as Role[]
            },
            {
                title: 'Cerrar sesión',
                path: 'logout',
                allowedRoles: [
                    { role: 'developerFull', canWrite: true },
                    { role: 'administratorGeneral', canWrite: true },
                    { role: 'coordinatorStatal', canWrite: true },
                    { role: 'coordinatorRegional', canWrite: true },
                    { role: 'technical', canWrite: true },
                    { role: 'farmer', canWrite: true },
                    { role: 'company', canWrite: true },
                    { role: 'group', canWrite: true },
                    { role: 'institution', canWrite: true }
                ] as Role[]
            }
        ],
        allowedRoles: [
            { role: 'developerFull', canWrite: true },
            { role: 'administratorGeneral', canWrite: true },
            { role: 'coordinatorStatal', canWrite: true },
            { role: 'coordinatorRegional', canWrite: true },
            { role: 'technical', canWrite: true },
            { role: 'farmer', canWrite: true },
            { role: 'company', canWrite: true },
            { role: 'group', canWrite: true },
            { role: 'institution', canWrite: true }
        ] as Role[]
    },
    {
        icon: faUsers,
        title: 'Usuarios',
        path: 'users',
        parent: null,
        allowedRoles: [
            { role: 'developerFull', canWrite: true },
            { role: 'administratorGeneral', canWrite: true },
            { role: 'coordinatorStatal', canWrite: true },
            { role: 'coordinatorRegional', canWrite: true },
            { role: 'company', canWrite: true },
            { role: 'group', canWrite: true },
            { role: 'institution', canWrite: true }
        ] as Role[]
    },
    {
        icon: faSeedling,
        title: 'Catalogo de tipos de cultivo',
        path: 'crops',
        parent: null,
        allowedRoles: [
            { role: 'developerFull', canWrite: true },
            { role: 'administratorGeneral', canWrite: true },
            { role: 'coordinatorStatal', canWrite: true },
        ] as Role[]
    },
    {
        icon: faMountain,
        title: 'Predios',
        path: 'lands',
        parent: null,
        allowedRoles: [
            { role: 'developerFull', canWrite: true },
            { role: 'administratorGeneral', canWrite: true },
            { role: 'coordinatorStatal', canWrite: true },
            { role: 'coordinatorRegional', canWrite: true },
            { role: 'technical', canWrite: false },
            { role: 'farmer', canWrite: false },
            { role: 'company', canWrite: false },
            { role: 'group', canWrite: false },
            { role: 'institution', canWrite: false }
        ] as Role[]
    },
    {
        icon: faClipboard,
        title: 'Cuestionarios',
        path: 'questionnaires',
        parent: null,
        allowedRoles: [
            { role: 'developerFull', canWrite: true },
            { role: 'administratorGeneral', canWrite: true },
            { role: 'coordinatorStatal', canWrite: false },
            { role: 'coordinatorRegional', canWrite: false },
        ] as Role[]
    },
    {
        icon: faLayerGroup,
        title: 'Planes de trabajo',
        path: 'work-schedules',
        parent: null,
        allowedRoles: [
            { role: 'developerFull', canWrite: true },
            { role: 'administratorGeneral', canWrite: true },
            { role: 'coordinatorStatal', canWrite: true },
            { role: 'coordinatorRegional', canWrite: false },
            { role: 'technical', canWrite: false },
            { role: 'farmer', canWrite: false },
            { role: 'company', canWrite: false },
            { role: 'group', canWrite: false },
            { role: 'institution', canWrite: false }
        ] as Role[]
    },
    {
        icon: faClipboardCheck,
        title: 'Resultados',
        path: 'results',
        parent: null,
        allowedRoles: [
            { role: 'developerFull', canWrite: true },
            { role: 'administratorGeneral', canWrite: true },
            { role: 'coordinatorStatal', canWrite: true },
            { role: 'coordinatorRegional', canWrite: true },
            { role: 'technical', canWrite: true },
            { role: 'farmer', canWrite: true },
            { role: 'company', canWrite: true },
            { role: 'group', canWrite: true },
            { role: 'institution', canWrite: true }
        ] as Role[]
    },
    {
        icon: faUserCog,
        title: 'Asignaciones',
        path: 'assignations',
        parent: null,
        allowedRoles: [
            { role: 'developerFull', canWrite: true },
            { role: 'administratorGeneral', canWrite: true },
            { role: 'coordinatorStatal', canWrite: true },
            { role: 'coordinatorRegional', canWrite: true },
        ] as Role[],
        submenu: [
            {
                title: 'Usuarios',
                path: 'users',
                allowedRoles: [
                    { role: 'developerFull', canWrite: true },
                    { role: 'administratorGeneral', canWrite: true },
                    { role: 'coordinatorStatal', canWrite: true },
                    { role: 'coordinatorRegional', canWrite: true },
                ] as Role[]
            },
            {
                title: 'Predios',
                path: 'lands',
                allowedRoles: [
                    { role: 'developerFull', canWrite: true },
                    { role: 'administratorGeneral', canWrite: true },
                    { role: 'coordinatorStatal', canWrite: true },
                    { role: 'coordinatorRegional', canWrite: true },
                ] as Role[]
            },

            // { title: 'Categorías', path: 'categories' },
        ],
    },
    // {
    //     icon: faCloud,
    //     title: 'Estación meteorológica',
    //     path: 'weather',
    // },
    // {
    //     icon: faBook,
    //     title: 'Material de apoyo',
    //     path: 'material',
    // },
];

export const sidebarRoutes = _sidebarRoutes.map(route => {
    if (route.submenu) {
        const routeSubmenu = route.submenu.map(submenu => {
            submenu.parent = route;
            submenu.path = `${submenu.parent.path}/${submenu.path}`;
            return submenu;
        });
        route.submenu = routeSubmenu;
    }

    return route;
});

export const flattenedSidebarRoutes = sidebarRoutes.reduce((acc, route) => {
    let reducedRoutes = [route];
    if (route.submenu) {
        reducedRoutes = reducedRoutes.concat(...route.submenu);
    }
    return acc.concat(reducedRoutes);
}, []);

