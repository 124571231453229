import { catchError, finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';

import { NotificationService } from '../notification.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class NotificationErrorInterceptor implements HttpInterceptor {
    constructor(private notificationService: NotificationService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(this.handleErrors.bind(this));
    }

    handleErrors(source: Observable<HttpEvent<any>>): Observable<HttpEvent<any> | string | HttpErrorResponse> {
        return source.pipe(
          catchError((response: HttpErrorResponse) => {
            const frienlyMessage = this._extractFriendlyMessage(response);
            const technicalMessage = this._extractTechnicalMessage(response);
            const nextError = new Error(response.message);

            if (! environment.production && technicalMessage) {
              this._displayErrorNotification(technicalMessage);
              nextError.message = technicalMessage;
            }
            if (frienlyMessage) {
              this._displayErrorNotification(frienlyMessage);
              nextError.message = frienlyMessage;
            }

            const res = new HttpErrorResponse({
              error: nextError,
              headers: response.headers,
              status: response.status,
              statusText: response.statusText,
              url: response.url
            });

            return throwError(() => res);
          })
        );
    }

    private _extractFriendlyMessage(response: HttpErrorResponse): string | null {
      return response.error.data?.friendlyMessage || null;
    }

    private _extractTechnicalMessage(response: HttpErrorResponse): string | null {
      return response.error.data?.message || null;
    }

    private _displayErrorNotification(message: string, responseStatus?: number): void {
      let errMessage = message;
      if (! errMessage) {
        if (responseStatus >= 400 && responseStatus < 500) {
          errMessage = 'Algo salió mal, verifique que los datos ingresados sean correctos.';
        } 
        if (responseStatus >= 500 && responseStatus < 600) {
          errMessage = 'Ocurrió un error en el servidor';
        } else {
          errMessage = 'Algo salió mal';
        }
      }

      this.notificationService.openErrorSnackBar({ message: errMessage });
    }
}

