import { Router, RouterOutlet } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FooterComponent } from '../../footer/footer.component';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'app-authorization-page',
    templateUrl: './authorization-page.component.html',
    styleUrls: ['./authorization-page.component.scss'],
    standalone: true,
    imports: [MatToolbar, RouterOutlet, FooterComponent]
})
export class AuthorizationPageComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  navigateHome(): void {
    const targetUrl = '/';
    this.router.navigate([targetUrl]);
  }
}
