<div class="dashboard-page">
    <app-sidebar
    [routes]="routes"
    [user]="loggedUser"
    [profilePhoto]="profilePhoto"
    [isSidebarCollapsed]="this.isSidebarCollapsed"
    (sidebarCollapsed)="handleSidebarCollapse($event)">
        <div class="dashboard-page__container"  [ngClass]="{ 'collapsed': this.isSidebarCollapsed }">
            <app-navigation-hierarchy></app-navigation-hierarchy>
            <router-outlet></router-outlet>
        </div>
    </app-sidebar>
    <app-footer></app-footer>
</div>
