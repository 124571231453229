import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IconService {
  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {}

  addSvg(icon: IconDefinition): Observable<{ iconName: string, svg: SVGElement }> {
    const svg: string =
    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${icon.icon[0]} ${icon.icon[1]}">
      <path d="${icon.icon[4]}" />
    </svg>`;
    const iconName = `${icon.prefix}:${icon.iconName}`;

    return this.iconRegistry.getNamedSvgIcon(icon.iconName, icon.prefix).pipe(
      map(svg => {
        return { iconName, svg };
      }),
      catchError(() => {
        // icon not found, so we add it
        this.iconRegistry.addSvgIconLiteralInNamespace(
          icon.prefix, icon.iconName, this.sanitizer.bypassSecurityTrustHtml(svg)
        );
        return this.iconRegistry.getNamedSvgIcon(icon.iconName, icon.prefix).pipe(
          map(svg => {
            return { iconName, svg };
          })
        );
      })
    );
  }
}
