import { SessionService } from '../session.service';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private router: Router, private sessionService: SessionService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const authHeader = this.sessionService.accessToken;

        let authReq = req.clone();
        const headers = authReq.headers;

        if (authHeader && ! req.url.includes('signin') && ! req.url.includes('validate_token_create_user')) {
            authReq = req.clone({
                setHeaders: { 'x-access-token': authHeader },
                // withCredentials: true
            });
        }

        return next.handle(authReq).pipe(this.handleErrors.bind(this));
    }

    handleErrors(source: Observable<HttpEvent<any>>): Observable<HttpEvent<any>> {
        return source.pipe(
          catchError((error: HttpErrorResponse) => {
            return error.status === 401 ? this.handle401(error) : throwError(() => error);
          })
        );
    }

    handle401(error: HttpErrorResponse): Observable<never> {
        // const authResHeader = error.headers.get('WWW-Authenticate');
        // if (/is expired/.test(authResHeader)) {
        this.router.navigate(['/signin']);
            // this.sessionService.refreshToken();
        // } else {
            // this.router.navigate(['authfailed']);
        // }
        return throwError(() => error);
    }
}
