import { State } from './state';
import { Municipality } from './municipality';
import { RoleType } from './role';
import { Settlement } from './settlement';
import { UserFormData } from 'src/app/shared/user-create/user-create-form.service';

interface UserMainData {
    name: string;
    lastName: string;
    email: string;
    password: string;
    currentPassword?: string;
    photo: Blob;
}

interface UserPersonalData {
    address: Address;
    birthDate: string;
    phone: string;
}

export interface Address {
    country: string;
    idState: string | State;
    idMunicipality: string | Municipality;
    locality: string;
    idSettlement: string | Settlement;
    zipCode: string;
    street: string;
    homeNumber: string;
}

export interface UserHierarchyRankDetail {
    _id_foreign: string;
    role: string;
    name: string;
    lastName: string;
}

interface UserHierarchy {
    subordinates: UserHierarchyRank;
    highRanking: UserHierarchyRank;
}

interface UserHierarchyRank {
    direct: UserHierarchyRankDetail[];
    indirect: UserHierarchyRankDetail[];
}

export interface UserWorkplace {
    _id?: string;
    idState: string | State;
    idMunicipalities: (string | Municipality)[];
}

export interface User {
    _id: string;
    mainData: UserMainData;
    personalData: UserPersonalData;
    role: RoleType;
    rfc: string;
    curp: string;
    hierarchy?: UserHierarchy;
    createdAt: string;
    createdBy: string;
    workplaces: UserWorkplace[];
    active: boolean;
}

export interface FlatUser {
    _id: string;
    name: string;
    lastName: string;
    curp?: string;
}


export function isUser(element: User | UserFormData): element is User {
    return (element as User).mainData !== undefined;
}
