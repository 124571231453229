import { Router } from '@angular/router';
import { Component, OnDestroy, OnInit, Output, EventEmitter, Input, ChangeDetectionStrategy } from '@angular/core';

import { SidebarRouteItem } from './sidebar-routes';
import { User } from '../core/model/user';
import { SidebarService } from './sidebar.service';
import { IconService } from '../core/icon.service';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { MatSelectionListChange, MatSelectionList, MatListOption, MatListItemIcon, MatListItemTitle } from '@angular/material/list';
import { SelectionModel } from '@angular/cdk/collections';
import { SafeUrl } from '@angular/platform-browser';
import { MatSidenav, MatSidenavContainer, MatSidenavContent } from '@angular/material/sidenav';
import { NgClass, AsyncPipe, TitleCasePipe } from '@angular/common';
import { MatMiniFabButton } from '@angular/material/button';
import { MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelDescription } from '@angular/material/expansion';
import { MatIcon } from '@angular/material/icon';
import { AccessControlDirective } from '../shared/directives/access-control.directive';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatSidenavContainer, MatSidenav, MatSelectionList, AccessControlDirective, MatListOption, MatIcon, MatListItemIcon, MatListItemTitle, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelDescription, MatSidenavContent, MatMiniFabButton, NgClass, AsyncPipe, TitleCasePipe]
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input() routes: SidebarRouteItem[];
  @Input() isSidebarCollapsed: boolean;
  @Input() user: User;
  @Input() profilePhoto: SafeUrl;

  @Output() routeChanged = new EventEmitter<string>();
  @Output() sidebarCollapsed = new EventEmitter<boolean>();
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  selectionModel: SelectionModel<SidebarRouteItem>;
  defaultProfilePhoto = '../../assets/images/user.png';

  constructor(
    private router: Router,
    private sidebarService: SidebarService,
    public iconService: IconService) { }

  ngOnInit(): void {
    this.selectionModel = new SelectionModel(false);
    this.sidebarService.changeState({ collapsed: this.isSidebarCollapsed });

    this._selectActiveRoute(this.router.url);
  }

  toggleSidebarCollapse(sidenav: MatSidenav): void {
    sidenav.toggle().then((result) => {
      this.isSidebarCollapsed = ! sidenav.opened;
      this.sidebarService.changeState({ collapsed: this.isSidebarCollapsed });
      this.sidebarCollapsed.emit(this.isSidebarCollapsed);
    });
  }

  /**
   * Navigates to the root page "/" and resets all routes
   */
  navigateHome(): void {
    const targetUrl = '/';
    this.resetAllActiveRoutes();
    this.sidebarService.changeState({ currentRoute: targetUrl });
    this.routeChanged.emit(targetUrl);
    this.router.navigate([targetUrl]);
  }

  /**
   * Set the activated state of all the routes to false
   */
  private resetAllActiveRoutes(): void {
    this.selectionModel.clear();
  }

  /**
   * Reset sidebar routes as they are persisted even after the component destruction
   */
  ngOnDestroy(): void {
    this.resetAllActiveRoutes();
  }

  private _selectActiveRoute(route: string): void {
    const segments = route.split('/').filter(segment => segment !== '');
  }

  isChildSelected(route: SidebarRouteItem): boolean {
    let isSelected = false;
    if (route.submenu) {
      const selectedItem = this.selectionModel.selected && this.selectionModel.selected.length > 0
        ? this.selectionModel.selected[0]
        : null;
      isSelected = !! route.submenu.find(submenu => submenu === selectedItem);
    }

    return isSelected;
  }

  handleNavigationChange(event: MatSelectionListChange): void {
    const route = event.options[0].value as SidebarRouteItem;
    let url: string = route.path;
    this.sidebarService.changeState({ currentRoute: route.path });

    this.selectionModel.select(route);
    this.router.navigate([url]);
  }

}

