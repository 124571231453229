<div class="navigation-hierarchy">
  @for (segment of this.urlSegments; track segment; let i = $index) {
    <div class="navigation-hierarchy__route">
      <a class="navigation-hierarchy__route__link"
        [routerLink]="[segment.url]">
        {{ segment.displayText | titlecase }}
      </a>
      @if (i < (this.urlSegments.length - 1)) {
        <mat-icon class="navigation-hierarchy__route__separator"
        [svgIcon]="(iconService.addSvg(faCaretRight) | async).iconName"></mat-icon>
      }
    </div>
  }
</div>