import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, Validators } from '@angular/forms';
import { isQuestionnaireQuestion, isNesteable, isQuestionnaireRoot, isRepeteable, QuestionnaireItemType } from 'src/app/core/model/questionnaire';
import { NotificationService } from 'src/app/core/notification.service';
import { ContainerForm, QuestionForm } from '../models/questionnaire-form';

@Injectable()
export class QuestionnaireHandlerService {
  private categorizedQuestionnaire = new Map<QuestionnaireItemType, AbstractControl[]>();
  private fastAccessQuestionnaire = new Map<string, AbstractControl>();

  constructor(private notificationService: NotificationService) {
  }

  setMappings(questionnaire: FormGroup): void {
    this.clear();

    if (this.hasChildren(questionnaire)) {
      const children = this.getChildrenArray(questionnaire);
      children.controls.forEach(child => {
        this.addMappings(child);
      });
    }
  }

  addMappings(item: AbstractControl): void {
    this._insertCategorizedQuestionnaire(item);
    this._insertFastAccessQuestionnaire(item);

    if (isRepeteable(item.value)) {
      this.addMappings(item.get('template'));
    } else {
      if (this.hasChildren(item)) {
        const itemChildren = this.getChildrenArray(item);

        itemChildren.controls.forEach(child => {
            this.addMappings(child);
        });
      }
    }
 }

  private _insertCategorizedQuestionnaire(item: AbstractControl): void {
    const itemType = item.get('type').value;

    if (! this.categorizedQuestionnaire.has(itemType)) {
      this.categorizedQuestionnaire.set(itemType, []);
    }

    const items = this.categorizedQuestionnaire.get(itemType);
    // items = [...items, item];
    this.categorizedQuestionnaire.set(itemType, [...new Set([...items, item])]);

  }

  private _insertFastAccessQuestionnaire(item: AbstractControl): void {
    const itemId = item.get('_id').value;

    if (! this.fastAccessQuestionnaire.has(itemId)) {
      this.fastAccessQuestionnaire.set(itemId, item);
    }
  }

  removeMappings(item: AbstractControl): void {
    this._removeCategorizedQuestionnaire(item);
    this._removeFastAccessQuestionnaire(item);

    if (this.hasChildren(item)) {
      const itemChildren = this.getChildrenArray(item);
      itemChildren.controls.forEach(child => {
          this.removeMappings(child);
      });
    }
  }

  hasChildren(item: AbstractControl): boolean {
    return isRepeteable(item.value)
          || isNesteable(item.value)
          || isQuestionnaireQuestion(item.value);
  }

  getChildrenArray(item: AbstractControl): FormArray | null {
    let childrenArray: FormArray;

    if (isRepeteable(item.value)) {
              childrenArray = item.get('template.children') as FormArray;
      } else if (isNesteable(item.value)) {
              childrenArray = item.get('children') as FormArray;
    } else if (isQuestionnaireQuestion(item.value)) {
              childrenArray = item.get('options') as FormArray;
    } else {
              childrenArray = null;
    }

    return childrenArray;
  }

  private _removeCategorizedQuestionnaire(item: AbstractControl): void {
    const itemType = item.get('type').value;

    if (this.categorizedQuestionnaire.has(itemType)) {
      let categoryItems = this.categorizedQuestionnaire.get(itemType);
      categoryItems = categoryItems.filter(categoryItem => categoryItem.get('_id').value !== item.get('_id').value);
      this.categorizedQuestionnaire.set(itemType, categoryItems);
    }

    if (this.categorizedQuestionnaire.get(itemType).length === 0) {
      this.categorizedQuestionnaire.delete(itemType);
    }
  }

  private _removeFastAccessQuestionnaire(item: AbstractControl): void {
    const itemId = item.get('_id').value;

    if (this.fastAccessQuestionnaire.has(itemId)) {
      this.fastAccessQuestionnaire.delete(itemId);
    }
  }

  findByComponentId(componentId: string): FormGroup | null {
    return this.fastAccessQuestionnaire.get(componentId) as any;
  }

  findByComponentType(componentType: QuestionnaireItemType): FormGroup[] | undefined {
    return this.categorizedQuestionnaire.get(componentType) as FormGroup[];
  }

  findAllQuestions(): FormGroup[] {
    let accumulator = [];

    const inputQuestions = this.categorizedQuestionnaire.get('input') as FormGroup[] || [];
    const selectQuestions = this.categorizedQuestionnaire.get('select') as FormGroup[] || [];
    accumulator = [...inputQuestions, ...selectQuestions];

    return accumulator;
  }

  findAllRepetitionQuestions(): FormGroup[] {
    return this.findAllQuestions()
      .filter(question => this._checkIfParentIsRepetition(question));
  }

  findAllNonRepetitionQuestions(): FormGroup[] {
    return this.findAllQuestions().filter(control => {
      return ! this.findAllRepetitionQuestions()
        .find(repetitionControl => repetitionControl.get('_id').value === control.get('_id').value);
    });
  }

  private _checkIfParentIsRepetition(control: FormGroup): boolean {
    const parentId = control.get('parentId').value;
    if (parentId) {
      const parentElement = this.fastAccessQuestionnaire.get(parentId);
      if (parentElement) {
        if (isRepeteable(parentElement.value)) {
          return true;
        } else {
          return this._checkIfParentIsRepetition(parentElement as FormGroup);
        }
      } else {
        return false;
      }
    }
    return false;
  }

  findNonRepetitionInputQuestions(): FormGroup[] {
    return this.findAllNonRepetitionQuestions()
      .filter(control => control.get('type').value === 'input');
  }

  findNonRepetitionSelectQuestions(): FormGroup[] {
    return this.findAllNonRepetitionQuestions()
      .filter(control => control.get('type').value === 'select');
  }

  findRepetitionInputQuestions(): FormGroup[] {
    return this.findAllRepetitionQuestions()
      .filter(control => control.get('type').value === 'input');
  }

  findRepetitionSelectQuestions(): FormGroup[] {
    return this.findAllRepetitionQuestions()
      .filter(control => control.get('type').value === 'select');
  }

  findNumericRepetitionQuestions(): FormGroup[] {
    return this.findAllRepetitionQuestions()
      .filter(control => control.get('restrictions.type').value === 'number');
  }

  findNumericRepetitionInputQuestions(): FormGroup[] {
    return this.findNumericRepetitionQuestions()
      .filter(control => control.get('type').value === 'input');
  }

  findNumericRepetitionSelectQuestions(): FormGroup[] {
    return this.findNumericRepetitionQuestions()
      .filter(control => control.get('type').value === 'select');
  }

  findNumericQuestions(): FormGroup[] {
    return this.findAllQuestions()
      .filter(control => control.get('restrictions.type').value === 'number');
  }

  findNumericInputQuestions(): FormGroup[] {
    return this.findNumericQuestions()
      .filter(control => control.get('type').value === 'input');
  }

  findNumericSelectQuestions(): FormGroup[] {
    return this.findNumericQuestions()
      .filter(control => control.get('type').value === 'select');
  }

  findNumericNonRepetitionQuestions(): FormGroup[] {
    return this.findAllNonRepetitionQuestions()
      .filter(control => control.get('restrictions.type').value === 'number');
  }

  findNumericNonRepetitionInputQuestions(): FormGroup[] {
    return this.findNumericNonRepetitionQuestions()
      .filter(control => control.get('type').value === 'input');
  }

  findNumericNonRepetitionSelectQuestions(): FormGroup[] {
    return this.findNumericNonRepetitionQuestions()
      .filter(control => control.get('type').value === 'select');
  }

  insertItem(container: FormArray, item: AbstractControl, index?: number): void {
    if (item.get('type').value === 'sampleMap') {
      if (this.canInsertMapComponent(container)) {
        this._insertItem(container, item, index);
      } else {
        this.notificationService.openWarningSnackBar({
          message: 'Solo puede existir un componente de mapa por cuestionario y no puede estar dentro de una repetición'
        });
        console.warn('solo puede existir un maximo de 1 componente de mapa por cuestionario y no puede estar dentro de una repetición.')
      }
    } else if (item.get('type').value === 'repetition') {
      if (this.canInsertRepetitionComponent(container)) {
        this._insertItem(container, item, index);
      } else {
        this.notificationService.openWarningSnackBar({
          message: 'No es posible anidar repeticiones dentro de otras'
        });
        console.warn('no es posible anidar repeticiones dentro de repeticiones')
      }
    } else {
      this._insertItem(container, item, index);
    }
  }

  private _insertItem(container: FormArray, item: AbstractControl, index?: number): void {
    if (index === undefined) {
      container.push(item);
    } else {
      container.insert(index, item);
    }
    this._updateItemParent(item, container);
    this.addMappings(item);
  }

  copyItem(previousContainer: FormArray, container: FormArray, previousIndex: number, currentIndex: number): void {
    const item = previousContainer[previousIndex].value;
    this.insertItem(container, item, currentIndex);
  }

  // Edition
  moveItem(container: FormArray, previousIndex: number, currentIndex: number): void {
      const previousPosItem = container.at(previousIndex);
      const itemInTargetPos = container.at(currentIndex);

      container.removeAt(currentIndex);
      container.insert(currentIndex, previousPosItem);
      container.removeAt(previousIndex);
      container.insert(previousIndex, itemInTargetPos);

      this._updateItemParent(previousPosItem, container);
  }

  // Edition
  transferItem(previousContainer: FormArray, container: FormArray, previousIndex: number, currentIndex: number): void {
      const itemToTransfer = previousContainer.at(previousIndex);
      container.insert(currentIndex, itemToTransfer);
      previousContainer.removeAt(previousIndex);

      this._updateItemParent(itemToTransfer, container);
      this._removeCategorizedQuestionnaire(itemToTransfer);
      this.addMappings(itemToTransfer);
  }

  // Deletion
  removeItem(item: AbstractControl, parent: FormGroup | FormArray): void {
    let parentArray: FormArray;
    const parentValue = parent.value;

    if (isQuestionnaireQuestion(parentValue)) {
      parentArray = (parent as FormGroup<QuestionForm>).controls.options;
    } else if (isNesteable(parentValue)) {
      parentArray = (parent as FormGroup<ContainerForm>).get('children') as FormArray;
    } else {
      parentArray = parent as FormArray;
    }
    const parentArrayValue = parentArray.value;
    const indexToRemove = parentArrayValue.findIndex(element => {
      return element === item.value;
    });
    if (indexToRemove !== -1) {
        parentArray.removeAt(indexToRemove);
        this.removeMappings(item);
    }
  }

  private _updateItemParent(item: AbstractControl, container: FormArray): void {
    const itemParentIdControl = item.get('parentId');
    const parentIdControl = item.get('parentId');

    // don't update if the parent is the root element because at creation time an id does not exist
    if (! isQuestionnaireRoot(container.parent.value)) {
      const containerIdValue = container.parent
        ? container.parent.get('_id').value
        : null;
      if (containerIdValue) {
        itemParentIdControl.patchValue(containerIdValue);
        parentIdControl.addValidators(Validators.required);
      }
    } else {
      parentIdControl.removeValidators(Validators.required); // remove the validator as the parent value is expected to be null
    }
    parentIdControl.updateValueAndValidity();
  }

  clear(): void {
    this.categorizedQuestionnaire.clear();
    this.fastAccessQuestionnaire.clear();
  }

  canInsertMapComponent(parentContainer: FormArray): boolean {
    let canInsert = false;
    const maxAllowedAmount = 1;
    const existingSampleMapComponents = this.findByComponentType('sampleMap') || [];

    if (! parentContainer.parent
      || ! parentContainer.parent.parent 
      || ! parentContainer.parent.parent.get('type')
      || (
          parentContainer.parent.parent.get('type').value !== 'sampleMap'
          && parentContainer.parent.parent.get('type').value !== 'repetition')
        ) {
      canInsert = true;
    }

    return canInsert && existingSampleMapComponents.length < maxAllowedAmount;
  }

  canInsertRepetitionComponent(parentContainer: FormArray): boolean {
    let canInsert = false;

    if (! parentContainer.parent
      || ! parentContainer.parent.parent 
      || ! parentContainer.parent.parent.get('type')
      || (
          // parentContainer.parent.parent.get('type').value !== 'sampleMap'
          // && 
          parentContainer.parent.parent.get('type').value !== 'repetition')
        ) {
      canInsert = true;
    }
  
    return canInsert;
  }

}
