import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import camelCaseKeys from 'camelcase-keys';

@Injectable()
export class TransformResponseInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event) => {
        // only modify json responses
        if (event instanceof HttpResponse && event.headers.has('content-type')
          && event.headers.get('content-type').includes('application/json')) {
          const responseToCamelCase = camelCaseKeys(event.body, { deep: true, exclude: ['_id', '_id_foreign'] });
    
          if (responseToCamelCase.data) {
            const body = responseToCamelCase.data;
            return event.clone({ body });
          }
          return event.clone({ body: responseToCamelCase }); // undefined means dont change it
        }
        return event;
      })
    );
  }
}
