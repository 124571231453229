import { Component, OnInit } from '@angular/core';
import { asapScheduler } from 'rxjs';
import { observeOn } from 'rxjs/operators';
import { BusyService } from 'src/app/core/busy.service';
import { MatProgressBar } from '@angular/material/progress-bar';

@Component({
    selector: 'app-busy',
    templateUrl: './busy.component.html',
    styleUrls: ['./busy.component.scss'],
    standalone: true,
    imports: [MatProgressBar]
})
export class BusyComponent implements OnInit {
  busy: boolean;

  constructor(private busyService: BusyService) { }

  ngOnInit(): void {
      this.busyService.busyState$.pipe(observeOn(asapScheduler))
      .subscribe(state => this.busy = state.isBusy);
  }

}
