import { Injectable } from '@angular/core';
import { Route, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SessionService } from '../session.service';
import { RoleAccessService } from '../services/role-access.service';
import { MatDialog } from '@angular/material/dialog';
import { map, Observable } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogData } from '../model/confirmation-dialog-data';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
  deniedMessage = 'Unauthorized access denied';

  constructor(
    private sessionService: SessionService,
    private router: Router,
    private roleAccessService: RoleAccessService,
    private matDialog: MatDialog
  ) {}

  canLoad(route: Route): boolean {
    let allowedAccess = false;

    if (this.sessionService.isLoggedIn) {
      allowedAccess = true;
    } else {
      const url = `/${route.path}`;
      this.router.navigate(['/signin'], { queryParams: { redirectTo: url } });
      console.warn(this.deniedMessage);
      allowedAccess = false;
    }

    return allowedAccess;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    let allowedAccess = false;

    if (this.sessionService.isLoggedIn) {
      allowedAccess = this.roleAccessService.hasAccess(route.data.allowedRoles);
    } else if (state.url !== '/'){
      const url = `${state.url}`;
      this.router.navigate(['/signin'], { queryParams: { redirectTo: url } });
      console.warn(this.deniedMessage);
    } else {
      this.router.navigate(['/signin']);
    }

    return allowedAccess
      ? allowedAccess
      : this.sessionService.isLoggedIn
        ? this._displayNoAccessDialog()
        : false;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    return this.canActivate(route, state);
  }

  private _displayNoAccessDialog(): Observable<boolean> {
    const data: ConfirmationDialogData = {
      title: 'Acceso denegado',
      description: 'Tu usuario no tiene permitido ingresar a la ruta especificada'
    };
    return this.matDialog.open(ConfirmationDialogComponent, {
      data
    }).afterClosed().pipe(
      map(() => false)
    );
  }
}
