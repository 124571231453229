import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { TransformResponseInterceptor } from './transform-response.interceptor';
import { TransformRequestBodyInterceptor } from './transform-request-body.interceptor';
import { AuthInterceptor } from './auth.interceptor';
import { BusyInterceptor } from './busy.interceptor';
import { NotificationErrorInterceptor } from './notification-error.interceptor';

export const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TransformRequestBodyInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TransformResponseInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BusyInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NotificationErrorInterceptor, multi: true },
];
