import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { HttpService, HttpServiceExtras } from '../core/model/http-service';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ProfilePhotoContainer } from '../core/model/profile-photo';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Injectable({providedIn: 'root'})
export class ProfilePhotoService implements HttpService {

  private readonly root = environment.API;

  constructor(
    private http: HttpClient,
    private sanitization: DomSanitizer,
  ) { }

  getAll(extras?: any): Observable<ProfilePhotoContainer[]> {
    const fullUrl = `${this.root}/profile-photo/read_metadata`;
    return this.http.get<ProfilePhotoContainer[]>(fullUrl);
  }

  find(id: string): Observable<Blob> {
    const fullUrl = `${this.root}/profile-photo/read_photo/_id/${id}`;
    return this.http.get(fullUrl, { headers: new HttpHeaders({ Accept: 'image/jpeg' }), responseType: 'blob' }).pipe(
      catchError((err: HttpErrorResponse)=> {
        let result: Observable<any>;
        if (err.status === 404) {
          result = of(null);
        } else {
          result = throwError(() => err);
        }

        return result;
      })
    );
  }

  findMetadata(id: string): Observable<ProfilePhotoContainer> {
    const fullUrl = `${this.root}/profile-photo/read_metadata/_id/${id}`;
    return this.http.get<ProfilePhotoContainer[]>(fullUrl,).pipe(
        map(photo => photo ? photo[0] : null)
    );
  }

  findByUser(userId: string): Observable<SafeUrl> {
    const fullUrl = `${this.root}/profile-photo/read_photo/profile._id_user/${userId}`;
    return this.http.get(fullUrl, { headers: new HttpHeaders({ Accept: 'image/jpeg' }), responseType: 'blob' }).pipe(
      mergeMap(photo => {
        return this.readFile(photo).pipe(
          map(file => this.sanitization.bypassSecurityTrustResourceUrl(file))
        )
      }), 
      catchError((err: HttpErrorResponse)=> {
        let result: Observable<any>;
        if (err.status === 404) {
          result = of(null);
        } else {
          result = throwError(() => err);
        }

        return result;
      })
    );
  }

  findMetadataByUser(userId: string): Observable<ProfilePhotoContainer> {
    const fullUrl = `${this.root}/profile-photo/read_metadata/profile._id_user/${userId}`;
    return this.http.get<ProfilePhotoContainer>(fullUrl);
  }

  findOwn(): Observable<Blob> {
    const fullUrl = `${this.root}/profile-photo/read_own`;
    return this.http.get(fullUrl, { headers: new HttpHeaders({ Accept: 'image/jpeg' }), responseType: 'blob' });
  }

  create(data: File, extras?: HttpServiceExtras): Observable<any> {
    const fullUrl = `${this.root}/profile-photo/create`;
    const formData = new FormData();
    formData.append('image', data, 'photo-' + Date.now());
    // const headers = new HttpHeaders({'Content-Type': 'file'});
    // const options = { headers };

    return this.http.post<any>(fullUrl, formData);
  }

  update(data: any, id?: string, extras?: { options: HttpServiceExtras; }): Observable<any> {
    throw new Error('Method not implemented.');
  }

  softDelete(id: string): Observable<any> {
    throw new Error('Method not implemented.');
  }

  activate(id: string): Observable<any> {
    throw new Error('Method not implemented.');
  }

  delete(id: string): Observable<any> {
    const fullUrl = `${this.root}/profile-photo/delete/_id/${id}`;
    return this.http.delete(fullUrl);
  }

  private readFile(blob: Blob): Observable<string> {
    return new Observable(obs => {
      const reader = new FileReader();

      reader.onerror = err => obs.error(err);
      reader.onabort = err => obs.error(err);
      reader.onload = () => obs.next(reader.result as string);
      reader.onloadend = () => obs.complete();

      return reader.readAsDataURL(blob);
    });
  }
}
