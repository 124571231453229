import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import snakecaseKeys from 'snakecase-keys';

@Injectable()
export class TransformRequestBodyInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    let bodyReq: HttpRequest<any>;
    if (req.body instanceof FormData || req.url.includes('read_filter')) {
      bodyReq = req.clone(); // do not alter the contents of the file upoaded
    } else {
      bodyReq = req.clone({
        body: req.body ? snakecaseKeys(req.body, {deep: true, exclude: ['_id', '_id_foreign']}) : req.body
      });
    }

    return next.handle(bodyReq);
  }
}
