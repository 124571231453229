import { Injectable } from '@angular/core';

const databaseRoles = [
  'developerFull',
  'administratorGeneral',
  'coordinatorStatal',
  'coordinatorRegional',
  'technical',
  'farmer',
  'group',
  'company',
  'institution'
];

const spanishRoles = [
  'desarrollador',
  'administrador general',
  'cordinador estatal',
  'cordinador regional',
  'técnico',
  'agricultor',
  'grupo',
  'empresa',
  'institución'
];

@Injectable({
  providedIn: 'root'
})
export class RoleLocalizerService {

  constructor() { }

  localize(role: string, locale: 'es'): string {
    let value: string;

    switch (locale) {
      case 'es':
        value = this.toSpanish(role);
        break;
    }

    return value;
  }

  localizedToOriginal(role: string, locale: 'es'): string {
    let value: string;

    switch (locale) {
      case 'es':
        value = this.spanishToOriginal(role);
        break;
    }

    return value;
  }

  private toSpanish(role: string): string {
    const originalIndex = databaseRoles.findIndex(originalRole => originalRole === role);
    return originalIndex !== -1 ? spanishRoles[originalIndex] : role;
  }

  private spanishToOriginal(role: string): string {
    const spanishIndex = spanishRoles.findIndex(spanishRole => spanishRole === role);
    return spanishIndex !== -1 ? databaseRoles[spanishIndex] : role;
  }
}
