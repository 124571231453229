import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { SessionService } from './../core/session.service';

@Component({
    selector: 'app-logout',
    template: `<div></div>`,
    styles: [``],
    standalone: true
})
export class LogoutComponent implements OnInit {

  constructor(private sessionService: SessionService, private router: Router) { }

  ngOnInit(): void {
    this.sessionService.logout();
    this.router.navigate(['/']);
  }

}
