<section class="authorization-page">
    <mat-toolbar style="background-color: #3e924e; padding-bottom: 0.5rem;">
        <img
            class="authorization-page__logo"
            alt="Atider Logo"
            src="../../assets/images/atider_logo_green.png"
            (click)="navigateHome()">
            <span style="color: white">Plataforma de Control Atider</span>
    </mat-toolbar>
    
    <router-outlet></router-outlet>

<app-footer></app-footer>