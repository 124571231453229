import { Injectable } from '@angular/core';

import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private snackBar: MatSnackBar) { }

  openSuccessSnackBar(extras?: { message?: string, action?: string, config?: MatSnackBarConfig }): MatSnackBarRef<TextOnlySnackBar> {
    const snackBarRef = this.snackBar.open(
      extras && extras.message ? extras.message : 'Cambios guardados',
      extras && extras.action ? extras.action : 'Aceptar',
      {
        panelClass: extras && extras.config && extras.config.panelClass ? extras.config.panelClass : 'snack-bar__success',
        duration: extras && extras.config && extras.config.duration ? extras.config.duration : 5 * 1000,
      }
    );

    return snackBarRef;
  }

  openErrorSnackBar(extras?: { message?: string, action?: string, config?: MatSnackBarConfig }): MatSnackBarRef<TextOnlySnackBar> {
    const snackBarRef = this.snackBar.open(
      extras && extras.message ? extras.message : 'Algo salió mal',
      extras && extras.action ? extras.action : 'Aceptar',
      {
        panelClass: extras && extras.config && extras.config.panelClass ? extras.config.panelClass : 'snack-bar__error',
        duration: extras && extras.config && extras.config.duration ? extras.config.duration : 5 * 1000,
      }
    );

    return snackBarRef;
  }

  openWarningSnackBar(extras?: { message?: string, action?: string, config?: MatSnackBarConfig }): MatSnackBarRef<TextOnlySnackBar> {
    const snackBarRef = this.snackBar.open(
      extras && extras.message ? extras.message : 'Advertencia',
      extras && extras.action ? extras.action : 'Aceptar',
      {
        panelClass: extras && extras.config && extras.config.panelClass ? extras.config.panelClass : 'snack-bar__warning',
        duration: extras && extras.config && extras.config.duration ? extras.config.duration : 5 * 1000,
      }
    );

    return snackBarRef;
  }
}
