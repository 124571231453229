import { Injectable } from '@angular/core';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';

export interface BusyPayload {
    isBusy: boolean;
    message?: string;
}

@Injectable({ providedIn: 'root' })
export class BusyService {
    private subject = new ReplaySubject<BusyPayload>();
    private busyCounter = 0;
    busyState$ = this.subject.asObservable();

    constructor(private router: Router) {
        this._listenToRouteNavigation();
    }

    addRequest(message: string): void {
        this.busyCounter++;
        const payload: BusyPayload = { isBusy: true, message };
        this.subject.next(payload);
    }

    removeRequest(): void {
        this.busyCounter--;
        if (this.busyCounter <= 0) {
            const notBusyPayload: BusyPayload = { isBusy: false };
            this.subject.next(notBusyPayload);
        }
    }

    private _listenToRouteNavigation(): void {
        this.router.events.subscribe(event => {
            if (event instanceof RouteConfigLoadStart) {
                this.addRequest('route change');
            } else if (event instanceof RouteConfigLoadEnd) {
              this.removeRequest();
            }
          });
    }
}
