@if (busy) {
  <div class="busy">
    <mat-progress-bar class="busy__content" mode="query"></mat-progress-bar>
    <!-- <div class="sk-folding-cube">
    <div class="sk-cube1 sk-cube"></div>
    <div class="sk-cube2 sk-cube"></div>
    <div class="sk-cube4 sk-cube"></div>
    <div class="sk-cube3 sk-cube"></div>
  </div> -->
</div>
}
