import { enableProdMode, LOCALE_ID, importProvidersFrom } from '@angular/core';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { provideAnimations } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { AppRoutingModule } from './app/app-routing';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { MAT_LUXON_DATE_ADAPTER_OPTIONS, provideLuxonDateAdapter } from '@angular/material-luxon-adapter';
import { httpInterceptorProviders } from './app/core/interceptors/http-interceptor-providers';
import { registerLocaleData } from '@angular/common';
import localeMx from '@angular/common/locales/es-MX';

if (environment.production) {
  enableProdMode();
}

registerLocaleData(localeMx);

bootstrapApplication(AppComponent, {
    providers: [
        provideLuxonDateAdapter(),
        importProvidersFrom(BrowserModule, AppRoutingModule, FormsModule, ReactiveFormsModule, MatProgressBarModule, MatListModule, MatSnackBarModule, MatFormFieldModule, MatInputModule, MatIconModule, MatToolbarModule, MatButtonModule
        ),
        httpInterceptorProviders,
        { provide: LOCALE_ID, useValue: 'es-MX' },
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
        { provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
    ]
})
  .catch(err => console.error(err));
