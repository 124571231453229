import { defer, Observable, of, OperatorFunction, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { isUser, User } from './../core/model/user';
import { environment } from './../../environments/environment';
import { UserFormData } from '../shared/user-create/user-create-form.service';
import { HttpService, HttpServiceExtras } from '../core/model/http-service';
import { State } from '../core/model/state';
import { Municipality } from '../core/model/municipality';
import { Settlement } from '../core/model/settlement';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { ProfilePhotoService } from './profile-photo.service';
import { RoleType } from '../core/model/role';
import { RoleLocalizerService } from '../core/services/role-localizer.service';
import { Country } from '../core/model/country';
import { formatLuxonDate } from '../core/utils';

@Injectable({providedIn: 'root'})
export class UsersService implements HttpService {

    private readonly root = environment.API;

    constructor(
        private http: HttpClient,
        private profilePhotoService: ProfilePhotoService,
        private roleLocalizer: RoleLocalizerService
        ) { }

    getAll(extras?: {
        active?: boolean,
        municipality?: string,
        role?: RoleType,
        email?: string,
        phone?: string,
        unassingedWorkplace?: boolean;
        workplaceIdStates?: string[],
        workplaceIdMunicipalities?: string[],
        useOperatorOR?: boolean
    }): Observable<User[]> {
        const urlAction = extras && (extras.useOperatorOR || extras.unassingedWorkplace) ? 'read_or' : 'read';
        const fullUrl = `${this.root}/user/${urlAction}/`;
        let params = new HttpParams();
        if (extras) {
            params = extras.active != null ? params.set('active', String(extras.active)) : params;
            params = extras.municipality ? params.set('personal_data.address.municipality', extras.municipality) : params;
            params = extras.role ? params.set('role', extras.role) : params;
            params = extras.email ? params.set('main_data.email', extras.email) : params;
            params = extras.phone ? params.set('personal_data.phone', extras.phone) : params;
            params = extras.unassingedWorkplace ? params.set('workplaces', 'no-exists-attribute') : params;

            if (extras.workplaceIdStates) {
                extras.workplaceIdStates.forEach(idState => {
                    params = params.append('workplaces.id_state', idState);
                });
            }
            if (extras.workplaceIdMunicipalities) {
                extras.workplaceIdMunicipalities.forEach(idMunicipality => {
                    params = params.append('workplaces.id_municipalities', idMunicipality);
                });
            }
        }
        const options = { params };

        return this.http.get<User[]>(fullUrl, options);
    }

    find(id: string): Observable<User> {
        const usersUrl = `${this.root}/user/read/_id`;
        const urlWithParams = `${usersUrl}/${id}`;

        return this.http.get<User[]>(urlWithParams).pipe(
            map((user: User[]) => {
                return user && user[0] ? user[0] : null;
            }),
            catchError(err => throwError(() => err))
        );
    }

    create(data: UserFormData, extras?: HttpServiceExtras): Observable<User> {
        const usersUrl = `${this.root}/user`;

        const headers = new HttpHeaders({'x-access-token': extras.token});
        const options = { headers };

        const user = this._mapFormDataToPartialUser(data);
        return this.http.post<User>(usersUrl, user, options).pipe(
            mergeMap(() => defer(() => !!data.uploadedPhoto
                ? this.profilePhotoService.create(data.uploadedPhoto)
                : of(null))
            ),
        )
    }

    search(query: { name?: string, workplace?: string, role?: string }, extras?: {
        active?: boolean,
        municipality?: string,
        role?: RoleType,
        email?: string,
        phone?: string,
        unassingedWorkplace?: boolean;
        workplaceIdStates?: string[],
        workplaceIdMunicipalities?: string[],
        useOperatorOR?: boolean
    }): Observable<User[]> {
        const fullUrl = `${this.root}/user/search`;

        let params = new HttpParams();
        params = query.name ? params.set('full_name', query.name) : params;
        params = query.workplace ? params.set('workplace', query.workplace) : params;
        params = query.role ? params.set('role', this.roleLocalizer.localizedToOriginal(query.role, 'es')) : params;

        if (extras) {
            params = extras.active != null ? params.set('active', String(extras.active)) : params;
            params = extras.municipality ? params.set('personal_data.address.municipality', extras.municipality) : params;
            params = extras.role ? params.set('role', extras.role) : params;
            params = extras.email ? params.set('main_data.email', extras.email) : params;
            params = extras.phone ? params.set('personal_data.phone', extras.phone) : params;
            params = extras.unassingedWorkplace ? params.set('workplaces', 'no-exists-attribute') : params;

            if (extras.workplaceIdStates) {
                extras.workplaceIdStates.forEach(idState => {
                    params = params.append('workplaces.id_state', idState);
                });
            }
            if (extras.workplaceIdMunicipalities) {
                extras.workplaceIdMunicipalities.forEach(idMunicipality => {
                    params = params.append('workplaces.id_municipalities', idMunicipality);
                });
            }
        }
        const options = { params };
        return this.http.get<User[]>(fullUrl, options);
    }

    update(data: UserFormData | User): Observable<any> {
        const usersUrl = `${this.root}/user/update/_id`;
        const urlWithParams = usersUrl + `/${data._id}`;
        const user = isUser(data)? data : this._mapFormDataToPartialUser(data);
        // if (data.currentPassword) {
        //     user.mainData.currentPassword = data.currentPassword;
        // }
        return this.http.put<User>(urlWithParams, user).pipe(
            switchMap(() => {
                return defer(() => ! isUser(data) && !!data.uploadedPhoto
                    ? this.profilePhotoService.create(data.uploadedPhoto)
                    : of(null)
                );
            })
        );
    }

    softDelete(id: string): Observable<any> {
        const usersUrl = `${this.root}/user/update_status/_id`;
        const fullUrl = usersUrl + `/${id}`;

        const body = { active: false };

        return this.http.put<User>(fullUrl, body);
    }

    activate(id: string): Observable<any> {
        const usersUrl = `${this.root}/user/update_status/_id`;
        const fullUrl = usersUrl + `/${id}`;

        const body = { active: true };

        return this.http.put<User>(fullUrl, body);
    }

    delete(id: string): Observable<any> {
        const usersUrl = `${this.root}/user/delete/_id`;
        const fullUrl = usersUrl + `/${id}`;

        return this.http.delete<User>(fullUrl);
    }

    getByRole(role: string): Observable<User[]> {
        const fullUrl = `${this.root}/user/read/role/${role}`;

        return this.http.get<User[]>(fullUrl);
    }

    getByMunicipality(municipality: string): Observable<User[]> {
        const fullUrl = `${this.root}/user/read/personal_data.address.municipality/${municipality}`;

        return this.http.get<User[]>(fullUrl);
    }

    verifyUniqueness(extras: { _id?: string, email?: string, phone?: string }): Observable<{isValid: boolean}> {
        const fullUrl = `${this.root}/user/verify`;
        let params = new HttpParams();
        if (extras) {
            params = extras._id ? params.set('_id', extras._id) : params;
            params = extras.email ? params.set('main_data.email', extras.email) : params;
            params = extras.phone ? params.set('personal_data.phone', extras.phone) : params;
        }
        const options = { params };

        return this.http.get<{isValid: boolean}>(fullUrl, options);
    }

    private _mapFormDataToPartialUser(data: UserFormData): Partial<User> {
        const user: Partial<User> = {
            mainData: {
                name: data.name,
                lastName: data.lastName,
                email: data.email,
                photo: null,
                password: data.password
            },
            personalData: {
                address: {
                    country: this._extractLocationName(data.country),
                    idState: this._extractLocationId(data.idState),
                    idMunicipality: this._extractLocationId(data.idMunicipality),
                    locality: data.locality,
                    street: data.street,
                    idSettlement: data.idSettlement ? this._extractLocationId(data.idSettlement) : null,
                    zipCode: data.zipCode,
                    homeNumber: data.homeNumber
                },
                phone: data.phone,
                birthDate: formatLuxonDate(data.birthdate),
            },
            role: data.role,
            rfc: data.rfc,
            curp: data.curp,
            workplaces: data.workplaces.map(workplace => {
                return {
                    idState: (workplace.idState as State)._id,
                    idMunicipalities: workplace.idMunicipalities.map((municipality) => {
                        return (municipality as Municipality)._id;
                    })
                };
            })
        };

        return user;
    }

    private _mapNonNullFieldsFormDataToPartialUser(data: UserFormData): Partial<User> {
        const mainData = Object.assign({},
            data.name && { name: data.name },
            data.lastName && { lastName: data.lastName },
            data.email && { email: data.email },
            data.photo && { photo: data.photo },
            data.password && { password: data.password },
        );
        const address = Object.assign({},
            data.country && { country: data.country },
            data.idState && { state: data.idState },
            data.idMunicipality && { municipality: data.idMunicipality },
            data.locality && { locality: data.locality },
            data.street && { street: data.street },
            data.idSettlement && { settlement: data.idSettlement },
            data.zipCode && { zipCode: data.zipCode },
            data.homeNumber && { homeNumber: data.homeNumber }
        );
        const personalData = Object.assign({},
            address && { address },
            data.phone && { phone: data.phone },
            data.birthdate && { birthDate: data.birthdate }
        );
        const user = Object.assign({},
            mainData && { mainData },
            personalData && { personalData },
            data.role == null ? null : { role: data.role }
        );

        return user;
    }

    private _extractLocationId(location: Country | State | Municipality | Settlement | string): string {
        return typeof location === 'string' ? location : location._id;
    }

    private _extractLocationName(location: Country | State | Municipality | Settlement | string): string {
        return typeof location === 'string' ? location : location.name;
    }
}
