import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Role, RoleType } from 'src/app/core/model/role';
import { RoleAccessService } from 'src/app/core/services/role-access.service';

@Directive({
    selector: '[appAccessControl]',
    standalone: true
})
export class AccessControlDirective {
  @Input() set appAccessControl(allowedRoles: Role[]) {
    this._hasAccess(allowedRoles);
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private roleAccessService: RoleAccessService,
  ) { }


  private _hasAccess(allowedRoles: Role[]): void {
      const canAccess = this.roleAccessService.hasAccess(allowedRoles);
      if (canAccess) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
      // this.elRef.nativeElement.style.display = canAccess ? 'block' : 'none';
  }

}
