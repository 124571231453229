import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ScheduledLand, WorkSchedule } from 'src/app/core/model/work-schedule';
import { Land } from 'src/app/core/model/land';
import { QuestionnairePackage } from 'src/app/core/questionnaire-package';

interface WorkScheduleForm {
  _id: FormControl<string>;
  name: FormControl<string>;
  description: FormControl<string>;
  scheduledLand: FormArray<FormControl<{ idLand: string }>>;
  cropObjective: FormControl<string>;
  idQuestionnairePackage: FormControl<string[]>;
  questionnairePackage: FormArray<FormControl<QuestionnairePackage>>;
}

export interface WorkScheduleFormData {
  _id: string;
  name: string;
  description: string;
  scheduledLand: { idLand: string }[];
  cropObjective: string;
  idQuestionnairePackage: string[];
  questionnairePackage: QuestionnairePackage[];
}

@Injectable()
export class WorkScheduleFormService {
  private workScheduleForm: FormGroup<WorkScheduleForm>;

  constructor(private fb: FormBuilder) {
    this._initForm();

    // Update the idQuestionnairePackage list using the value of the helper property
    this.questionnairePackage.valueChanges.subscribe({
      next: (changedValue: QuestionnairePackage[]) => {
        const mappedValue = changedValue.map(value => {
          return value ? value._id : null;
        });
        this.idQuestionnairePackage.patchValue(mappedValue);
      }
    });
  }

  loadWorkSchedule(workSchedule: WorkSchedule): void {
    this._id.patchValue(workSchedule._id);
    this.name.patchValue(workSchedule.name);
    // this.startDate.patchValue(workSchedule);
    // this.endDate.patchValue(workSchedule.endDate);
    this.description.patchValue(workSchedule.description);
    this.cropObjective.patchValue(workSchedule.cropObjective);
    this.questionnairePackage.patchValue(workSchedule.idQuestionnairePackage);
    this.setLands(workSchedule.scheduledLand);
  }

  private _initForm(): void {
    this.workScheduleForm = this.fb.group<WorkScheduleForm>({
      _id: this.fb.control(null),
      name: this.fb.control(null, [Validators.required]),
      description: this.fb.control(null, [Validators.required]),
      scheduledLand: this.fb.array<FormControl<{ idLand: string; }>>([], [Validators.required]),
      cropObjective: this.fb.control(null, [Validators.required]),
      idQuestionnairePackage: this.fb.control([], [Validators.required ]),
      questionnairePackage: this.fb.array([
        this.fb.control(null),
        this.fb.control(null),
        this.fb.control(null),
      ]),
    });
  }

  addLand(landToAdd: Land): void {
    const value = this.landsValue;
    const index = value.findIndex(land => land.idLand === landToAdd._id);
    if (index === -1) {
      this.scheduledLand.push(this.fb.control({ idLand: landToAdd._id }));
    }
  }

  setLands(lands: ScheduledLand[]): void {
    this.scheduledLand.clear();
    lands.forEach(land => {
      this.scheduledLand.push(this.fb.control({ idLand: land.idLand as string }));
    });
  }

  removeLand(landToRemove: Land): void {
    const value = this.landsValue;
    const index = value.findIndex(land => land.idLand === landToRemove._id);
    if (index !== -1) {
      this.scheduledLand.removeAt(index);
    }
  }

  setQuestionnairePackageElement(index: number, questionnairePackage: QuestionnairePackage): void {
    this.questionnairePackage.at(index).patchValue(questionnairePackage);
  }

  emptyQuestionnairePackageElement(index: number): void {
    this.questionnairePackage.at(index).patchValue(null);
  }

  get form(): FormGroup<WorkScheduleForm> {
    return this.workScheduleForm;
  }

  get value(): WorkScheduleFormData {
    return this.workScheduleForm.value as WorkScheduleFormData;
  }

  get valid(): boolean {
    return this.workScheduleForm.valid;
  }

  get _id(): FormControl<string> {
    return this.workScheduleForm.get('_id') as FormControl<string>;
  }

  get name(): FormControl<string> {
    return this.workScheduleForm.get('name') as FormControl<string>;
  }

  get description(): FormControl<string> {
    return this.workScheduleForm.get('description') as FormControl<string>;
  }

  get scheduledLand(): FormArray<FormControl<{ idLand: string }>> {
    return this.workScheduleForm.get('scheduledLand') as FormArray<FormControl<{ idLand: string }>>;
  }

  get cropObjective(): FormControl<string> {
    return this.workScheduleForm.get('cropObjective') as FormControl<string>;
  }

  get idQuestionnairePackage(): FormArray<FormControl<string>> {
    return this.workScheduleForm.get('idQuestionnairePackage') as FormArray<FormControl<string>>;
  }

  get questionnairePackage(): FormArray<FormControl<QuestionnairePackage>> {
    return this.workScheduleForm.get('questionnairePackage') as FormArray<FormControl<QuestionnairePackage>>;
  }

  get landsValue(): { idLand: string }[] {
    return this.scheduledLand.value;
  }
}
