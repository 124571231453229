import { finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BusyService } from './../busy.service';

@Injectable()
export class BusyInterceptor implements HttpInterceptor {
    constructor(private busyService: BusyService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.busyService.addRequest(req.method);
        return next.handle(req).pipe(
            finalize(() => {
                this.busyService.removeRequest();
            })
        );
    }
}
