import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { filter, tap } from 'rxjs/operators';
import { IconService } from 'src/app/core/icon.service';
import { NavigationHierarchyLocalizeService } from './navigation-hierarchy-localize.service';
import { AsyncPipe, TitleCasePipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';

interface UrlSegments {
  url: string;
  displayText: string;
}

@Component({
    selector: 'app-navigation-hierarchy',
    templateUrl: './navigation-hierarchy.component.html',
    styleUrls: ['./navigation-hierarchy.component.scss'],
    standalone: true,
    imports: [RouterLink, MatIcon, AsyncPipe, TitleCasePipe]
})
export class NavigationHierarchyComponent implements OnInit {

  urlSegments: UrlSegments[];
  faCaretRight = faCaretRight;

  constructor(
    private router: Router,
    private navigationLocalizationService: NavigationHierarchyLocalizeService,
    public iconService: IconService
  ) { }

  ngOnInit(): void {
    const url = this.router.url || '';
    this._routeTitle(url);

    this.router.events.pipe(
      filter(events => events instanceof NavigationEnd),
      tap((navigationEndEvent: NavigationEnd) => {
        this._routeTitle(navigationEndEvent.url);
      })
    ).subscribe();
  }

  private _routeTitle(url: string): void {
    url = this._removePos1SlashInUrl(url);
    const segments = url.split('/');


    this.urlSegments = segments
    .map(segment => {
      const segmentUrl = url.substring(0, (url.indexOf(segment) + segment.length));
      return { url: '/' + segmentUrl, displayText: this.navigationLocalizationService.localize(segment) }; // absolute urls
    })
    .filter(segment => segment.displayText !== '');
  }

  private _removePos1SlashInUrl(url: string): string {
    if (url[0] === '/') {
      url = url.substring(1);
    }

    return url;
  }

}
