import { Component, OnInit } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { mergeMap, of, tap } from 'rxjs';
import { User } from 'src/app/core/model/user';
import { SessionService } from 'src/app/core/session.service';
import { ProfilePhotoService } from 'src/app/users/profile-photo.service';
import { UsersService } from 'src/app/users/users.service';

import { sidebarRoutes } from '../../sidebar/sidebar-routes';
import { FooterComponent } from '../../footer/footer.component';
import { RouterOutlet } from '@angular/router';
import { NavigationHierarchyComponent } from '../../shared/navigation-hierarchy/navigation-hierarchy.component';
import { NgClass } from '@angular/common';
import { SidebarComponent } from '../../sidebar/sidebar.component';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard-page.component.html',
    styleUrls: ['./dashboard-page.component.scss'],
    standalone: true,
    imports: [SidebarComponent, NgClass, NavigationHierarchyComponent, RouterOutlet, FooterComponent]
})
export class DashboardPageComponent implements OnInit {

  routes = sidebarRoutes.slice();
  isSidebarCollapsed = false;
  loggedUser: User;
  profilePhoto: SafeUrl;

  constructor(
    private sessionService: SessionService,
    private userService: UsersService,
    private profilePhotoService: ProfilePhotoService) { }

  ngOnInit(): void {
    const userId = this.sessionService.loggedUserId;
    if (userId) {
      this.userService.find(userId).pipe(
        tap(user => {
          this.loggedUser = user;
        }),
        mergeMap(user => {
          return user
            ? this.profilePhotoService.findByUser(user._id).pipe(
              tap(photo => this.profilePhoto = photo)
            )
            : of(null);
        })
      )
      .subscribe();
    }
  }

  handleSidebarCollapse(collapsed: boolean): void {
    this.isSidebarCollapsed = collapsed;
  }
}
