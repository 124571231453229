import { Pipe, PipeTransform } from '@angular/core';
import { RoleLocalizerService } from 'src/app/core/services/role-localizer.service';

@Pipe({
    name: 'displayrole',
    standalone: true
})

export class DisplayRolePipe implements PipeTransform {
    constructor(private roleLocalizer: RoleLocalizerService) {}

    transform(value: string): string;
    transform(value: null | undefined): null;
    transform(value: string | null | undefined): string | null {
        if (value == null) { return null; }
        if (typeof value !== 'string') {
            throw Error('DisplayRolePipe must have an string as value');
        }

        return this.roleLocalizer.localize(value, 'es');
    }
}
