import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface SidebarState {
    collapsed?: boolean;
    currentRoute?: string;
}

@Injectable({providedIn: 'root'})
export class SidebarService implements OnDestroy {
    private _state: SidebarState = { collapsed: undefined, currentRoute: undefined };
    private sidebarSubject = new BehaviorSubject<SidebarState>(this._state);
    sidebarState$ = this.sidebarSubject.asObservable();

    constructor() { }

    changeState(state: SidebarState): void {
        const collapsed = (state.collapsed || state.collapsed === false) ?
            state.collapsed :
            (this._state.collapsed || this._state.collapsed === false) ?
            this._state.collapsed :
            null;
        const currentRoute = state.currentRoute ? state.currentRoute : this._state.currentRoute ? this._state.currentRoute : null;
        const updatedState: SidebarState = { collapsed, currentRoute};
        this.sidebarSubject.next(updatedState);
    }

    ngOnDestroy(): void {
        this.sidebarSubject.next(null);
        this.sidebarSubject.complete();
    }
}
